
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>

		<v-container v-else>
			<v-layout row wrap mt-4>
				<v-flex xs12>
					<!-- {{rowsAll}} -->
					<v-img class="d-flex mx-auto align-center" :src="logobw" max-width="100px" alt="" />
					<h1 class="text-center" style="color:#B30000">{{$store.getters.language.data.extra.welcome}} {{user.user_name}}</h1>
				</v-flex>

				<v-flex xs12 v-if="user.user_role != 'viewer'">
					<v-card class="ma-3 rounded-lg" outlined>
						<v-card-title class="px-0">
							<div class="ma-4 my-2" style="width: 100%;" @click="to('/off_day_requests-view')">
								<h5 class="text-center" v-if=" rowsAll == 0">{{$store.getters.language.data.extra.no_pending}} </h5>
								<h2 class="text-center" v-else style="font-weight: bold; text-transform: uppercase;
                                "> <span style="color:#B30000"> {{rowsAll}}</span> {{$store.getters.language.data.extra.panding}}</h2>
							</div>
						</v-card-title>
					</v-card>
				</v-flex>
				<v-flex xs12 v-if="user.user_id == 17">
					<v-card class="ma-3 rounded-lg" outlined>
						<v-card-title class="px-0">
							<div class="ma-4 my-2" style="width: 100%;" @click="to('/off_day_requests-view')">
								<h5 class="text-center" v-if=" rowsAll == 0">{{$store.getters.language.data.extra.no_pending}} </h5>
								<h2 class="text-center" v-else style="font-weight: bold; text-transform: uppercase;
                                "> <span style="color:#B30000"> {{rowsAll}}</span> {{$store.getters.language.data.extra.panding}}</h2>
							</div>
						</v-card-title>
					</v-card>
				</v-flex>
				<v-flex xs12 v-if="user.user_role == 'hr'">
					<v-card class="ma-3 rounded-lg" outlined>
						<v-card-title class="px-0">
							<div class="ma-4 my-2" style="width: 100%;" @click="to('/off_day_requests-view')">
								<h5 class="text-center" v-if=" rowsview == 0">{{$store.getters.language.data.extra.no_pending_review}}</h5>
								<h2 class="text-center" v-else style="font-weight: bold; text-transform: uppercase;
                                "> <span style="color:#B30000"> {{rowsview}}</span> {{$store.getters.language.data.extra.pending_review}}</h2>
							</div>
						</v-card-title>
					</v-card>
				</v-flex>
				<v-row justify="center">
					<v-flex xs10 lg4 xl4 md5 sm5 v-for="(item, index) in navigation_links" :key="index">
						<v-col>
							<v-card class="ma-3 rounded-lg" outlined :style="{ borderColor: item.color_code }" style="border: 3px solid;" :to="item.path">
								<v-card-title class="px-0" :style="{ color: item.color_code }">
									<div class="ma-4 my-10" style="width: 100%;">
										<h5 class="text-center">{{ item.title.toUpperCase() }} <v-icon :style="{ color: item.color_code }">{{ item.icon }} </v-icon></h5>
										<h6 class="text-center">{{ item.text }}</h6>
									</div>
								</v-card-title>
							</v-card>
							<!-- if status true show the salary report card  -->

						</v-col>
					</v-flex>

				</v-row>
			</v-layout>
			<v-layout row wrap mt-4>

				<v-row justify="center">

					<v-flex xs10 lg4 xl4 md5 sm5 class="ma-3 px-3 my-4">

						<v-card v-if="status" class="ma-3 rounded-lg custom-card" outlined :to="'/salary_account'">
							<v-card-title class="px-0 custom-card-title">
								<div class="ma-4 my-10" style="width: 100%;">
									<h5 class="text-center custom-title">{{$store.getters.language.data.extra.salary_report}}<v-icon color="success">mdi-cash </v-icon></h5>
									<h6 class="text-center">{{$store.getters.language.data.extra.salary_ready}}</h6>
								</div>
							</v-card-title>
						</v-card>
						<v-card v-if="!status" class="ma-3 rounded-lg custom-card" outlined :to="'/'">
							<v-card-title class="px-0 custom-card-title">
								<div class="ma-4 my-10" style="width: 100%;">
									<h5 class="text-center custom-title">{{$store.getters.language.data.extra.salary_report}}<v-icon color="#B30000">mdi-cash </v-icon></h5>
									<h6 class="text-center">{{$store.getters.language.data.extra.no_salary_report}}</h6>
								</div>
							</v-card-title>
						</v-card>

					</v-flex>
				</v-row>
			</v-layout>
		</v-container>
	</div>
</template>
<script>
	import requests from '../requests/off_day_requests.request.js'

	export default {

		data() {
			return {
				status: 0,
				user: {},
				rowsAll: 0,
				rowsview: 0,
				loading: true,
			}
		},
		methods: {
			to(path) {
				this.$router.push(path)
			},
			readOffDayRequests() {
				this.loading = true
				let columns = {
					off_day_request_status: 'pending'
					,
					supervisor_id: this.user.position_id
				}
				requests.reportRequests(columns).then(r => {
					if (r.status == 200) {
						this.rowsAll = r.data.rows.length
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read OffDayRequests',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read OffDayRequests',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			readOffDayRequestview() {
				this.loading = true
				let columns = {
					off_day_request_status: 'viewing'
					,
					supervisor_id: this.user.position_id
				}
				requests.reportRequests(columns).then(r => {
					if (r.status == 200) {
						this.rowsview = r.data.rows.length
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read OffDayRequests',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read OffDayRequests',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
		},
		mounted() {
			this.status = this.$store.getters.salary_report_list[0].salary_report_status
			this.user = this.$store.getters.auth.user
			if (this.user.user_role != 'viewer') {
				this.readOffDayRequests();
			}
			if (this.user.user_role == 'hr') {
				this.readOffDayRequestview();
			}
			if (this.user.user_role == 'viewer') {
				this.loading = false
			}
		},

		computed: {
			logobw() {
				return require('@/assets/login.png')
			},
			navigation_links() {
				return this.$store.getters.language.data.nav.navigation_links
			},
		},
	}
</script>
<style scoped>
	.custom-card {
		border: 3px solid #b30000;
	}

	.custom-card-title {
		color: #b30000;
	}

	.custom-title {
		color: #b30000;
	}
</style>
